import React, { useEffect } from "react";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getOrderDetails, deliverOrder } from "../redux/actions/orderActions";
import { resetDeliverOrder, setItemsPrice } from "../redux/slices/orderSlices";

const OrderScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { id: orderId } = useParams();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error, itemsPrice } = orderDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    if (order) {
      dispatch(
        setItemsPrice(
          addDecimals(
            order.orderItems.reduce(
              (acc, item) => acc + item.price * item.qty,
              0
            )
          )
        )
      );
    }

    // order.itemsPrice = addDecimals(
    //   order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    // );
  }

  useEffect(() => {
    if (!userInfo) {
      history("/login");
    }

    if (!order || order._id !== orderId) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, order, history, userInfo]);

  useEffect(() => {
    if (successDeliver) {
      dispatch(resetDeliverOrder());
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, successDeliver]);

  const deliverHandler = () => {
    dispatch(deliverOrder(orderId));
  };

  return (
    <Container>
      <Button onClick={() => history(-1)}>Go Back</Button>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.message}</Message>
      ) : (
        <Container>
          <h1>Order{order && order._id}</h1>
          <Row>
            <Col md={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Shipping</h2>
                  <p>
                    <strong>
                      <b>Name: </b>
                    </strong>{" "}
                    {order && order.user.name}
                  </p>
                  <p>
                    <strong>
                      <b>Email: </b>
                    </strong>
                    <a href={`mailto:${order && order.user.email}`}>
                      {order && order.user.email}
                    </a>
                  </p>
                  <p>
                    <strong>
                      <b>Address: </b>
                    </strong>
                    {order && order.shippingAddress.address},{" "}
                    {order && order.shippingAddress.city},{" "}
                    {order && order.shippingAddress.postalCode},{" "}
                    {order && order.shippingAddress.country}
                  </p>
                  {order && order.isDelivered ? (
                    <Message variant="success">
                      Delivered on {order && order.deliveredAt.substring(0, 10)}
                    </Message>
                  ) : (
                    <Message variant="danger">Not Delivered</Message>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h2>Payment Method</h2>
                  <p>
                    <strong>
                      <b>Method: </b>
                    </strong>
                    {order && order.paymentMethod}
                  </p>
                  {order && order.isPaid ? (
                    <Message variant="success">
                      Paid on {order && order.paidAt.substring(0, 10)}
                    </Message>
                  ) : (
                    <Message variant="danger">Not Paid</Message>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <h2>Order Items</h2>
                  {order && order.orderItems.length === 0 ? (
                    <Message>Order is empty</Message>
                  ) : (
                    <ListGroup variant="flush">
                      {order &&
                        order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={1}>
                                <Image
                                  src={
                                    item && item.images && item.images[0].name
                                  }
                                  alt={item.name}
                                  fluid="true"
                                  rounded
                                ></Image>
                              </Col>
                              <Col>
                                <Link to={`/product/${item.product}`}>
                                  {item.name}
                                </Link>
                              </Col>
                              <Col md={4}>
                                {item.qty} x R{item.price} = R
                                {item.qty * item.price}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Order Summary</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Items</Col>
                      <Col>R{itemsPrice}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Shipping</Col>
                      <Col>R{order && order.shippingPrice}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Tax</Col>
                      <Col>R{order && order.taxPrice}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Total</Col>
                      <Col>R{order && order.totalPrice}</Col>
                    </Row>
                  </ListGroup.Item>
                  {loadingDeliver && <Loader />}
                  {userInfo &&
                    userInfo.isAdmin &&
                    order &&
                    order.isPaid &&
                    !order.isDelivered && (
                      <ListGroup.Item>
                        <Button
                          type="button"
                          className="btn btn-block"
                          onClick={deliverHandler}
                        >
                          Mark As Delivered
                        </Button>
                      </ListGroup.Item>
                    )}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default OrderScreen;
