import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios.instance";

export const contactForm = createAsyncThunk(
  "mail/contactForm",
  async ({ email, message, firstname, lastname }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axiosInstance.post(
        "/api/mail",
        { email, message, firstname, lastname },
        config
      );

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);
