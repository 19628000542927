import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios.instance";

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async ({ id, qty, size }, { rejectWithValue, getState }) => {
    try {
      const res = await axiosInstance.get(`/api/products/${id}`);

      const product = {
        product: res.data._id,
        name: res.data.name,
        images: res.data.images,
        price: res.data.price,
        countInStock: res.data.sizes.find((x) => x.size === size).countInStock,
        qty,
        size,
      };

      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );

      return product;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (id, { rejectWithValue, getState }) => {
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );

    return id;
  }
);

export const saveShippingAddress = createAsyncThunk(
  "cart/saveShippingAddress",
  async (address, { rejectWithValue }) => {
    localStorage.setItem("shippingAddress", JSON.stringify(address));

    return address;
  }
);

export const savePaymentMethod = createAsyncThunk(
  "cart/savePaymentMethod",
  async (paymentMethod, { rejectWithValue }) => {
    localStorage.setItem("paymentMethod", JSON.stringify(paymentMethod));

    return paymentMethod;
  }
);
