import React, { Fragment } from "react";
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Button,
  Box,
  Drawer,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const sidebarWidth = 240;

const AdminHeader = ({ window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div style={{ height: "90%" }}>
      <Toolbar
        style={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/images/emom_logo.png" alt="logo" style={{ width: 150 }} />
      </Toolbar>
      <Divider />
      <Stack
        direction="column"
        sx={{ height: "100%" }}
        justifyContent="space-between"
      >
        <List>
          {["Users", "Products", "Orders"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <Link
                to={`/admin/${text[0].toLowerCase() + text.slice(1)}`}
                style={{ textDecoration: "none" }}
              >
                <ListItemButton>
                  <ListItemText style={{ color: "#212121" }} primary={text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <Button
          onClick={() => navigate("/")}
          sx={{ mx: 2, backgroundColor: "#000" }}
          variant="contained"
        >
          Main Site
        </Button>
      </Stack>
    </div>
  );

  return (
    <Fragment>
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          width: { sm: sidebarWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Fragment>
  );
};

export default AdminHeader;
