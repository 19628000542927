import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
// import { Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import Message from "../components/Message";
// import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
// import { monthKPI } from "../actions/adminActions";
// import { listBookings } from '../actions/bookingActions'
// import AdminHeader from "../components/AdminHeader";
// import { listBabysitters } from '../actions/babysitterActions'
// import {
//   LineChart,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   Line,
// } from "recharts";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  // const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  // const d = new Date()

  // const getMonth = () => {
  // 	return monthNames[d.getMonth()]
  // }

  //   const chartData = [
  //     {
  //       month: "Jan",
  //       hours: 100,
  //     },
  //     {
  //       month: "Feb",
  //       hours: 130.2,
  //     },
  //     {
  //       month: "Mar",
  //       hours: 80.9,
  //     },
  //     {
  //       month: "Apr",
  //       hours: 60.5,
  //     },
  //     {
  //       month: "May",
  //       hours: 45.0,
  //     },
  //     {
  //       month: "Jun",
  //       hours: 70.0,
  //     },
  //     {
  //       month: "Jul",
  //       hours: 90.0,
  //     },
  //     {
  //       month: "Aug",
  //       hours: 140.0,
  //     },
  //     {
  //       month: "Sep",
  //       hours: 96.7,
  //     },
  //     {
  //       month: "Oct",
  //       hours: 107.93,
  //     },
  //   ];

  // const clientsGet = useSelector((state) => state.clientsGet)
  // const { loading: clientsLoading, error: clientsError, clients } = clientsGet

  //   const monthkpi = useSelector((state) => state.monthkpi);
  //   const { loading: kpiLoading, error: kpiError, data: kpiData } = monthkpi;

  // const bookingList = useSelector((state) => state.bookingList)
  // const { loading, error, bookings: bookinglist } = bookingList

  // const babysitterList = useSelector((state) => state.babysitterList)
  // const { loading: babyLoading, error: babyError, babysitters } = babysitterList

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
  }, [dispatch, userInfo, navigate]);

  return (
    <React.Fragment>
      <Container
        fluid="true"
        className="d-flex p-0 m-0 vh-100 align-items-center justify-content-center"
      >
        <h1>DASHBOARD</h1>
        {/* <Container
          className="d-flex align-items-center justify-content-center flex-column p-0"
          fluid
          style={{ marginLeft: "15%" }}
        >
          {kpiLoading ? (
            <Loader />
          ) : kpiError ? (
            <Message variant="danger">{kpiError}</Message>
          ) : (
            <Container className="p-3">
              <h3 className="p-3">Dashboard</h3>
              <Row className="mb-3">
                <Col>
                  <Card
                    className="border-0  rounded"
                    style={{ backgroundColor: "#ebfdef" }}
                  >
                    <Card.Body className="d-flex align-items-center">
                      <Col className="p-0 m-0 text-center">
                        <i
                          className="fa fa-history"
                          style={{ fontSize: "2em" }}
                        ></i>
                      </Col>
                      <Col className="p-0 m-0">
                        <h6 className="p-0">Hours Sold</h6>
                        <h3 className="p-0 m-0">{kpiData.hours_sold}</h3>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    className="border-0  rounded"
                    style={{ backgroundColor: "#ebfdef" }}
                  >
                    <Card.Body className="d-flex align-items-center">
                      <Col className="p-0 m-0 text-center">
                        <i
                          className="fa fa-history"
                          style={{ fontSize: "2em" }}
                        ></i>
                      </Col>
                      <Col className="p-0 m-0">
                        <h6 className="p-0">Hours COMPLETED</h6>
                        <h3 className="p-0 m-0">{kpiData.hours_completed}</h3>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    className="border-0  rounded"
                    style={{ backgroundColor: "#e8f0fb" }}
                  >
                    <Card.Body className="d-flex align-items-center">
                      <Col className="p-0 m-0 text-center">
                        <i
                          className="fa fa-user-clock"
                          style={{ fontSize: "2em" }}
                        ></i>
                      </Col>
                      <Col className="p-0 m-0">
                        <h6 className="p-0">Profit</h6>
                        <h3 className="p-0 m-0">R{kpiData.profit}</h3>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    className="border-0  rounded"
                    style={{ backgroundColor: "#ffefe7" }}
                  >
                    <Card.Body className="d-flex align-items-center">
                      <Col className="p-0 m-0 text-center">
                        <i
                          className="fa fa-bullseye"
                          style={{ fontSize: "2em" }}
                        ></i>
                      </Col>
                      <Col className="p-0 m-0">
                        <h6 className="p-0">Break Even</h6>
                        <h3 className="p-0 m-0">
                          1.7
                          <span
                            style={{ fontSize: "0.5em" }}
                            className="p-0 m-0"
                          >
                            hours
                          </span>
                        </h3>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card
                    className="border-0  rounded"
                    style={{ backgroundColor: "#ffefe7" }}
                  >
                    <Card.Body className="d-flex align-items-center">
                      <Col className="p-0 m-0 text-center">
                        <i
                          className="fa fa-bullseye"
                          style={{ fontSize: "2em" }}
                        ></i>
                      </Col>
                      <Col className="p-0 m-0">
                        <h6 className="p-0">Monthly Goal</h6>
                        <h3 className="p-0 m-0">10</h3>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="d-flex flex-row">
                <Col className="flex-column">
                  <Col>
                    <h3>Monthly Profit</h3>
                    <LineChart width={750} height={250} data={chartData}>
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip label="Hours" />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Line
                        type="monotone"
                        dataKey="hours"
                        stroke="#000"
                        yAxisId={0}
                      />
                    </LineChart>
                  </Col> */}
        {/* <Col>
										<h3>Recent Bookings</h3>
										{loading ? (
											<Loader />
										) : error ? (
											<Message variant='danger'>{error}</Message>
										) : (
											<Container fluid className='pr-5'>
												<Table responsive className='table-sm text-center'>
													<thead style={{ color: '#a2a2ac' }}>
														<tr>
															<th className='border-0'>ID</th>
															<th className='border-0'>CLIENT</th>
															<th className='border-0'>BABYSITTER</th>
															<th className='border-0'>START</th>
															<th className='border-0'>END</th>
															<th className='border-0'>STATUS</th>
														</tr>
													</thead>
													<tbody>
														{bookinglist.slice(0, 3).map((booking) => (
															<tr key={booking.id} style={{ color: '#000' }}>
																<td>{booking.id}</td>
																<td>{booking.client_id}</td>
																<td>{booking.babysitter_id}</td>
																<td>{`${new Date(booking.start_date).toLocaleDateString()} ${new Date(booking.start_date).toLocaleTimeString().slice(0, 5)}`}</td>
																<td>{`${new Date(booking.end_date).toLocaleDateString()} ${new Date(booking.end_date).toLocaleTimeString().slice(0, 5)}`}</td>
																<td>{booking.status}</td>
															</tr>
														))}
													</tbody>
												</Table>
											</Container>
										)}
									</Col> */}
        {/* </Col> */}
        {/* <Col md='auto' className='p-3 d-flex'>
									<Card style={{ backgroundColor: '#000' }} className='shadow rounded p-4 align-self-center'>
										<h5 style={{ color: '#fff' }} className='pb-3'>
											Top Babysitters
										</h5>
										{babyLoading ? (
											<Loader />
										) : babyError ? (
											<Message variant='danger'>{error}</Message>
										) : (
											<Table responsive className='table-sm text-center'>
												<thead style={{ color: '#a2a2ac' }}>
													<tr>
														<th className='border-0'>#</th>
														<th className='border-0'>NAME</th>
														<th className='border-0'>SURNAME</th>
													</tr>
												</thead>
												<tbody>
													{babysitters.slice(0, 5).map((babysitter, index) => (
														<tr key={babysitter.user_id} style={{ color: '#fff' }}>
															<td>{index + 1}</td>
															<td>{babysitter.name}</td>
															<td>{babysitter.surname}</td>
														</tr>
													))}
												</tbody>
											</Table>
										)}
									</Card>
								</Col> */}
        {/* </Row>
            </Container>
          )}
        </Container> */}
      </Container>
    </React.Fragment>
  );
};

export default AdminDashboard;
