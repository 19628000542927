import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Container } from "react-bootstrap";
// import Rating from "../components/Rating";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  listProductDetails,
  createProductReview,
} from "../redux/actions/productActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { resetProductReview } from "../redux/slices/productSlices";
import Meta from "../components/Meta";
import styled from "styled-components";

const StyledMainText = styled(Col)`
  .head-copy {
    padding-left: 1rem;
    margin-top: 1rem;
    color: var(--pry-orange);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    @media screen and (min-width: 729px) {
      & {
        font-size: 1rem;
        margin-top: 0;
      }
    }
  }
  h1 {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 0.5rem;
    @media screen and (min-width: 729px) {
      & {
        font-size: 3rem;
      }
    }
  }
  .info {
    margin-block: 1rem;
    color: var(--dark-grayish-blue);
    @media screen and (min-width: 729px) {
      & {
        margin-block: 2rem;
      }
    }
  }
  .price-text {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-block: 2rem;
    @media screen and (min-width: 729px) {
      & {
        flex-direction: column;
        row-gap: 1rem;
      }
    }
    div {
      display: flex;
      column-gap: 1rem;
    }
  }
  .new-price {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .discount {
    border-radius: 5px;
    background-color: var(--pry-pale-orange);
    color: var(--pry-orange);
    font-weight: bold;
    padding: 0.4rem;
    padding-bottom: 0.2rem;
  }
  .price {
    color: var(--dark-grayish-blue);
    font-weight: bold;
    text-decoration: line-through;
  }
  .price-control {
    display: grid;
    row-gap: 1rem;
    @media screen and (min-width: 729px) {
      & {
        grid-template-columns: 3.5fr 6.5fr;
        row-gap: 0;
        column-gap: 1rem;
        padding-right: 1rem;
      }
    }
    div {
      background-color: var(--light-grayish-blue);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      font-size: 1.7rem;
      font-weight: bold;
      button {
        background: none;
        border: none;
        cursor: pointer;
        font: inherit;
        color: var(--pry-orange);
        &:hover {
          opacity: 0.75;
        }
      }
      span {
        font-weight: bold;
      }
    }
    .cart-btn {
      background-color: #92c6eb;
      border: none;
      padding: 1rem 0;
      text-align: center;
      color: var(--white);
      cursor: pointer;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      transform: skew(-21deg);
      box-shadow: 5px 15px 20px 10px var(--pry-pale-orange);
      &:hover {
        opacity: 0.75;
      }
    }
  }
`;

const ProductScreen = () => {
  const dispatch = useDispatch();

  const [qty, setQty] = useState(1);
  // const [rating, setRating] = useState(0);
  // const [comment, setComment] = useState("");
  const [size, setSize] = useState("small");

  const [currentImage, setCurrentImage] = useState("");

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // const productReviewCreate = useSelector((state) => state.productReviewCreate);
  // const { error: errorProductReview, success: successProductReview } =
  //   productReviewCreate;

  const { id } = useParams();
  const history = useNavigate();

  useEffect(() => {
    // if (successProductReview) {
    //   alert("Review Submitted!");
    //   setRating(0);
    //   setComment("");
    //   dispatch(resetProductReview());
    // }

    dispatch(listProductDetails(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (product && product.images) {
      setCurrentImage(product.images[0].name);
    }
  }, [product]);

  const addToCartHandler = () => {
    history(`/cart/${id}?qty=${qty}&size=${size}`);
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   dispatch(createProductReview(id, { rating, comment }));
  // };

  return (
    <Container>
      <Meta title={product ? product.name : ""} />
      <Link className="btn btn-dark my-3" to="/shop">
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.message}</Message>
      ) : (
        product && (
          <React.Fragment>
            <Row>
              <Col md={6}>
                <Image src={currentImage} fluid="true"></Image>
                <Row className="pt-3">
                  <Col
                    className="flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      className="shadow-md"
                      style={{ display: "block", margin: "auto" }}
                      rounded="true"
                      onClick={() => setCurrentImage(product.images[0].name)}
                      src={
                        product.images
                          ? product.images[0].name
                          : "/images/sample.jpg"
                      }
                      fluid="true"
                    ></Image>
                  </Col>
                  <Col
                    className="flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    {product.images && product.images.length > 1 && (
                      <Image
                        className="shadow-md"
                        style={{ display: "block", margin: "auto" }}
                        rounded="true"
                        onClick={() => setCurrentImage(product.images[1].name)}
                        src={
                          product && product.images
                            ? product.images[1].name
                            : "/images/sample.jpg"
                        }
                        fluid="true"
                      ></Image>
                    )}
                  </Col>
                  <Col
                    className="flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    {product.images && product.images.length > 2 && (
                      <Image
                        className="shadow-md"
                        style={{ display: "block", margin: "auto" }}
                        rounded="true"
                        onClick={() => setCurrentImage(product.images[2].name)}
                        src={
                          product.images
                            ? product.images[2].name
                            : "/images/sample.jpg"
                        }
                        fluid="true"
                      ></Image>
                    )}
                  </Col>
                </Row>
              </Col>
              <StyledMainText md={6}>
                <p className="head-copy">emom</p>
                <h1>{product && product.name}</h1>
                <h6>Shipping calculated at checkout</h6>
                <p className="info">{product.description}</p>
                <Row className="w-50">
                  <Col
                    onClick={() =>
                      product.sizes[0].countInStock !== 0
                        ? setSize("small")
                        : false
                    }
                    style={{
                      cursor: product.sizes[0].countInStock !== 0 && "pointer",
                    }}
                  >
                    <h5
                      className="text-center"
                      style={{
                        border:
                          size === "small"
                            ? "2px solid #92C6EB"
                            : product.sizes[0].countInStock === 0
                            ? "2px solif #aaaaaa"
                            : "2px solid #fff",
                        transform: "skew(-21deg)",
                        padding: "0.3rem",
                        position: "relative",
                        backgroundColor:
                          product.sizes[0].countInStock === 0 && "#aaaaaa",
                        color: product.sizes[0].countInStock === 0 && "#999",
                      }}
                    >
                      S
                    </h5>
                  </Col>
                  <Col
                    onClick={() =>
                      product.sizes[1].countInStock !== 0
                        ? setSize("medium")
                        : false
                    }
                    style={{
                      cursor: product.sizes[1].countInStock !== 0 && "pointer",
                    }}
                  >
                    <h5
                      className="text-center"
                      style={{
                        border:
                          size === "medium"
                            ? "2px solid #92C6EB"
                            : product.sizes[1].countInStock === 0
                            ? "2px solif #aaaaaa"
                            : "2px solid #fff",
                        transform: "skew(-21deg)",
                        padding: "0.3rem",
                        backgroundColor:
                          product.sizes[1].countInStock === 0 && "#aaaaaa",
                        color: product.sizes[1].countInStock === 0 && "#999",
                      }}
                    >
                      M
                    </h5>
                  </Col>
                  <Col
                    onClick={() =>
                      product.sizes[2].countInStock !== 0
                        ? setSize("large")
                        : false
                    }
                    style={{
                      cursor: product.sizes[2].countInStock !== 0 && "pointer",
                    }}
                  >
                    <h5
                      className="text-center"
                      style={{
                        border:
                          size === "large"
                            ? "2px solid #92C6EB"
                            : product.sizes[1].countInStock === 0
                            ? "2px solif #aaaaaa"
                            : "2px solid #fff",
                        transform: "skew(-21deg)",
                        padding: "0.3rem",
                        backgroundColor:
                          product.sizes[2].countInStock === 0 && "#aaaaaa",
                        color: product.sizes[2].countInStock === 0 && "#999",
                      }}
                    >
                      L
                    </h5>
                  </Col>
                </Row>
                <div className="price-text">
                  <div>
                    <p className="new-price">R{product && product.price}.00</p>
                  </div>
                </div>
                <div className="price-control">
                  <div>
                    <button
                      onClick={() =>
                        setQty((prevCount) =>
                          prevCount > 0 ? prevCount - 1 : 0
                        )
                      }
                    >
                      -
                    </button>
                    <span> {qty} </span>
                    <button
                      onClick={() => setQty((prevCount) => prevCount + 1)}
                    >
                      +
                    </button>
                  </div>
                  <button className="cart-btn" onClick={addToCartHandler}>
                    <i className="fas fa-cart-plus"></i>
                    Add to cart
                  </button>
                </div>
              </StyledMainText>
            </Row>
            {/* <Row>
            <Col md={6}>
              <h2>Reviews</h2>
              {product && product.numReviews === 0 && (
                <Message>No Reviews</Message>
              )}
              <ListGroup variant="flush">
                {product &&
                  product.reviews &&
                  product.reviews.map((review) => (
                    <ListGroup.Item key={review._id}>
                      <strong>{review.name}</strong>
                      <Rating value={review.rating}></Rating>
                      <p>{review.createdAt.substring(0, 10)}</p>
                      <p>{review.comment}</p>
                    </ListGroup.Item>
                  ))}

                <ListGroup.Item>
                  <h2>Write a customer review</h2>
                  {errorProductReview && (
                    <Message variant="danger">{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Button type="submit" variant="primary" className="mt-3">
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row> */}
          </React.Fragment>
        )
      )}
    </Container>
  );
};

export default ProductScreen;
