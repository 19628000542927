import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Image,
  Badge,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/userActions";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [colorChange, setColorchange] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const dispatch = useDispatch();

  const location = useLocation();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);

  return (
    <header>
      <Navbar
        style={{
          backgroundColor: colorChange ? "rgba(0, 0, 0, 0.3)" : "transparent",
          transition: "background-color 0.3s linear",
        }}
        expand="lg"
        fixed={"top"}
        collapseOnSelect
        className="nav-class"
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                fluid="true"
                style={{ width: "150px" }}
                src={
                  colorChange
                    ? "/images/logo_white.png"
                    : "/images/emom_logo.png"
                }
              ></Image>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer
                to="/"
                style={{
                  color: location.pathname !== "/" ? "#000" : "#fff",
                }}
              >
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer
                to="/contact"
                style={{
                  color: location.pathname !== "/" ? "#000" : "#fff",
                }}
              >
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              <LinkContainer
                to="/shop"
                style={{
                  color: location.pathname !== "/" ? "#000" : "#fff",
                }}
              >
                <Nav.Link>Shop</Nav.Link>
              </LinkContainer>
              <LinkContainer
                to="/cart"
                style={{
                  color: "#fff",
                }}
              >
                <Nav.Link>
                  <Badge
                    pill
                    style={{
                      backgroundColor: "#ffe9d0",
                      color: "#fff",
                      fontSize: "1em",
                      marginTop: "-0.5em",
                    }}
                    className="px-2 py-2 "
                  >
                    <i className="fas fa-shopping-cart mr-2"></i>
                    {cartItems.length}
                  </Badge>
                </Nav.Link>
              </LinkContainer>
              {userInfo && userInfo.name ? (
                <NavDropdown
                  title={userInfo.name}
                  id="username"
                  style={{
                    color: location.pathname !== "/" ? "#000" : "#fff",
                  }}
                  menuVariant="light"
                >
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer
                  to="/login"
                  style={{
                    color: location.pathname !== "/" ? "#000" : "#fff",
                  }}
                >
                  <Nav.Link>
                    <i className="fas fa-user"></i>
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <LinkContainer
                  to="/admin/users"
                  style={{
                    color: location.pathname !== "/" ? "#000" : "#fff",
                  }}
                >
                  <Nav.Link>Dashboard</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
