import { createSlice } from "@reduxjs/toolkit";
import { contactForm } from "../actions/mailActions";

export const contactFormSlice = createSlice({
  name: "mail",
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetContactForm: (state) => {
      state = { success: false, loading: false, error: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contactForm.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(contactForm.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(contactForm.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const { resetContactForm } = contactFormSlice.actions;
