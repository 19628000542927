import React, { useEffect } from "react";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { Link, useNavigate } from "react-router-dom";
import { createOrder } from "../redux/actions/orderActions";
import {
  addItemsprice,
  addShippingPrice,
  addTaxPrice,
  addTotalPrice,
} from "../redux/slices/cartSlices";
import { resetOrder } from "../redux/slices/orderSlices";
import { resetCart } from "../redux/slices/cartSlices";

const PlaceOrderScreen = () => {
  var yoco = new window.YocoSDK({
    // publicKey: "pk_test_4b64662f9Arn4oo7ce44",
    publicKey: "pk_live_9207164c9Arn4oo3d404",
  });

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const history = useNavigate();

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  const handlePayment = (amount) => {
    yoco.showPopup({
      amountInCents: amount * 100,
      currency: "ZAR",
      name: "emom",
      description: "Awesome description",
      callback: function (result) {
        if (result.error) {
          const errorMessage = result.error.message;
          alert("error occured: " + errorMessage);
        } else {
          dispatch(
            createOrder({
              orderItems: cart.cartItems,
              shippingAddress: cart.shippingAddress,
              paymentMethod: cart.paymentMethod,
              itemsPrice: cart.itemsPrice,
              shippingPrice: cart.shippingPrice,
              taxPrice: cart.taxPrice,
              totalPrice: cart.totalPrice,
              token: result.id,
            })
          );
        }
      },
    });
  };

  useEffect(() => {
    const itemsPrice = addDecimals(
      cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );

    dispatch(addItemsprice(itemsPrice));

    const shippingPrice =
      cart.itemsPrice > 100 ? addDecimals(0) : addDecimals(100);

    dispatch(addShippingPrice(shippingPrice));

    const taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));

    dispatch(addTaxPrice(taxPrice));

    dispatch(
      addTotalPrice(
        (Number(itemsPrice) + Number(shippingPrice) + Number(taxPrice)).toFixed(
          2
        )
      )
    );

    if (success) {
      dispatch(resetCart());
      history(`/order/${order._id}`);
    }
    // eslint-disable-next-line
  }, [history, success]);

  useEffect(() => {
    if (success) {
      dispatch(resetOrder());
    }
  }, [success, dispatch]);

  return (
    <Container>
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                Payfast
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item && item.images && item.images[0].name}
                            alt={item.name}
                            fluid="true"
                            rounded
                          ></Image>
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x R{item.price} = R{item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>R{cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>R{cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>R{cart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>R{cart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                {error && <Message variant="danger">{error.message}</Message>}
              </ListGroup.Item>

              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems === 0}
                  // onClick={placeOrderHandler}
                  onClick={() => handlePayment(cart.totalPrice)}
                >
                  Pay Now
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PlaceOrderScreen;
