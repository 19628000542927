import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listProducts,
  deleteProduct,
  createProduct,
} from "../redux/actions/productActions";
import { useNavigate, useParams } from "react-router-dom";
import { resetCreateProduct } from "../redux/slices/productSlices";
import { resetProductUpdate } from "../redux/slices/productSlices";
import {
  Stack,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  TableHead,
  Grid,
  Button,
  IconButton,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const ProductListScreen = () => {
  const dispatch = useDispatch();

  const history = useNavigate();

  const { pageNumber } = useParams() || 1;

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (!userInfo.isAdmin) {
      history("/login");
    }

    if (successCreate) {
      history(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts("", pageNumber));
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  useEffect(() => {
    if (successCreate) {
      dispatch(resetCreateProduct());
    }
  }, [successCreate, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      dispatch(resetProductUpdate());
    }
  }, [dispatch, successUpdate]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteProduct(id));
    }
  };

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Helmet>
        <title>Products | Emom</title>
      </Helmet>
      {loading || loadingCreate || loadingDelete || loadingUpdate ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "80vw",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        products && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100%",
              backgroundColor: "#f2f2f2",
              pb: 5,
              px: 5,
            }}
          >
            <Stack
              sx={{
                flex: 1,
                px: 4,
                py: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "600" }}>
                Products
              </Typography>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#000" }}
                startIcon={<AddIcon />}
                onClick={createProductHandler}
              >
                New Product
              </Button>
            </Stack>
            <Stack sx={{ flex: 10 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Rating</TableCell>
                      <TableCell align="center">Stock</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{product._id}</TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.price}</TableCell>
                        <TableCell>{product.rating}</TableCell>
                        <TableCell align="center">
                          <Grid container spacing={2}>
                            {product.sizes.map((item, index) => (
                              <Grid item xs={12} lg={4} key={index}>
                                <Chip
                                  label={`${item.size} ${item.countInStock}`}
                                  color="primary"
                                  sx={{ fontWeight: 600 }}
                                  variant="outlined"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </TableCell>
                        <TableCell align="center">
                          <Link to={`/admin/product/${product._id}/edit`}>
                            <IconButton aria-label="delete">
                              <EditIcon />
                            </IconButton>
                          </Link>
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteHandler(product._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        )
      )}
    </Stack>
  );
};

export default ProductListScreen;
