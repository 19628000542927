import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listOrders } from "../redux/actions/orderActions";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Paper,
  TableHead,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const OrderListScreen = () => {
  const dispatch = useDispatch();

  const history = useNavigate();
  const location = useLocation();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      history("/login");
    }
  }, [dispatch, history, userInfo]);

  return (
    <Stack sx={{ width: "100%" }}>
      <Helmet>
        <title>Orders | Emom</title>
      </Helmet>
      {loading ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "80vw",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        orders && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100%",
              backgroundColor: "#f2f2f2",
              pb: 5,
              px: 5,
            }}
          >
            <Stack
              sx={{
                flex: 1,
                px: 4,
                py: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "600" }}>
                Orders
              </Typography>
            </Stack>
            <Stack sx={{ flex: 10 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ID</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell align="center">Paid</TableCell>
                      <TableCell align="center">Delivered</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{order._id}</TableCell>
                        <TableCell>{order.user.name}</TableCell>
                        <TableCell>{order.createdAt}</TableCell>
                        <TableCell>R{order.totalPrice}</TableCell>
                        <TableCell align="center">
                          {order.isPaid ? <CheckIcon /> : <CloseIcon />}
                        </TableCell>
                        <TableCell align="center">
                          {order.isDelivered ? <CheckIcon /> : <CloseIcon />}
                        </TableCell>
                        <TableCell align="center">
                          <Link to={`/order/${order._id}`}>
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: "#000" }}
                            >
                              Details
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        )
      )}
    </Stack>
    // <Container style={{ paddingTop: "3rem" }}>
    //   <h1>Orders</h1>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <Message variant="danger">{error}</Message>
    //   ) : (
    //     <Table
    //       striped
    //       bordered
    //       hover
    //       responsive
    //       className="table-sm text-center"
    //     >
    //       <thead>
    //         <tr>
    //           <th>ID</th>
    //           <th>USER</th>
    //           <th>DATE</th>
    //           <th>TOTAL</th>
    //           <th>PAID</th>
    //           <th>DELIVERED</th>
    //           <th></th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {orders.map((order) => (
    //           <tr key={order._id}>
    //             <td>{order._id}</td>
    //             <td>{order.user && order.user.name}</td>
    //             <td>{order.createdAt}</td>
    //             <td>R{order.totalPrice}</td>
    //             <td>
    //               {order.isPaid ? (
    //                 order.paidAt.substring(0, 10)
    //               ) : (
    //                 <i
    //                   className="fas fa-times-circle"
    //                   style={{ color: "red", fontSize: "1.2rem" }}
    //                 ></i>
    //               )}
    //             </td>
    //             <td>
    //               {order.isDelivered ? (
    //                 order.deliveredAt.substring(0, 10)
    //               ) : (
    //                 <i
    //                   className="fas fa-times-circle"
    //                   style={{ color: "red", fontSize: "1.2rem" }}
    //                 ></i>
    //               )}
    //             </td>
    //             <td>
    //               <LinkContainer to={`/order/${order._id}`}>
    //                 <Button variant="light" className="btn-sm">
    //                   Details
    //                 </Button>
    //               </LinkContainer>
    //             </td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </Table>
    //   )}
    // </Container>
  );
};

export default OrderListScreen;
