import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import CheckoutSteps from "../components/CheckoutSteps";
import { Container, Form } from "react-bootstrap";
import FormContainer from "../components/FormContainer";

const CheckoutScreen = ({ history }) => {
  const [name, setName] = useState("");
  // const [surname, setSurname] = useState("");
  // const [email, setEmail] = useState("");
  // const [cell, setCell] = useState("");
  // const [address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  // const [zip, setZip] = useState("");
  // const [secondguard, setSecondguard] = useState("");
  // const [secondguardcell, setSecondguardcell] = useState("");
  // const [housedoctor, setHousedoctor] = useState("");
  // const [housedoctorcell, setHousedoctorcell] = useState("");
  // const [emercon, setEmercon] = useState("");
  // const [emerconcell, setEmerconcell] = useState("");

  // const dispatch = useDispatch();

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;

  // useEffect(() => {
  //   if (!userInfo) {
  //     history.push("/login");
  //   } else {
  //     if (!user.name) {
  //     }
  //   }
  // }, [history, userInfo]);

  // const submitHandler = () => {
  //   console.log("submit");
  // };

  return (
    <React.Fragment>
      <Header />
      <Container>
        <CheckoutSteps step1 step2 />
        <FormContainer>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Form>
        </FormContainer>
      </Container>
    </React.Fragment>
  );
};

export default CheckoutScreen;
