import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Product = ({ product, variant }) => {
  return (
    <Card
      className="my-3"
      style={{ border: "none", backgroundColor: "transparent" }}
    >
      <Link to={`/product/${product._id}`}>
        <Card.Img
          src={product.images[0].name}
          variant="top"
          style={{ width: "100%" }}
        ></Card.Img>
      </Link>

      <Card.Body
        style={{
          textAlign: "left",
          padding: "0",
          paddingTop: "0.8rem",
          backgroundColor: "transparent",
        }}
      >
        <Link to={`/product/${product._id}`}>
          <Card.Title
            as="div"
            style={{
              fontSize: "1.5rem",
              padding: "0",
              margin: "0",
            }}
          >
            <strong style={{ color: variant === "light" ? "#000" : "#fff" }}>
              {product.name}
            </strong>
          </Card.Title>
        </Link>

        <Card.Text
          as="h4"
          style={{
            padding: "0",
            paddingTop: "0.5rem",
            color: variant === "light" ? "#000" : "#fff",
          }}
        >
          R{product.price}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
