import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Form,
  Button,
  Card,
  Container,
} from "react-bootstrap";
import { addToCart, removeFromCart } from "../redux/actions/cartActions";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const CartScreen = () => {
  const { id } = useParams();

  const location = useLocation();
  const history = useNavigate();

  const params = location.search ? location.search.split("?")[1] : 1;
  const qty = params !== 1 ? params.split("&")[0].split("=")[1] : 1;
  const size = params !== 1 ? params.split("&")[1].split("=")[1] : 1;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {
    if (id) {
      dispatch(addToCart({ id, qty, size }));
    }
  }, [dispatch, id, qty, size]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history("/login?redirect=shipping");
  };

  return (
    <Container>
      <Helmet>
        <title>My Cart | Emom</title>
      </Helmet>
      <Row>
        <Col md={8}>
          <h1>Shopping Cart</h1>
          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/">Go Back</Link>{" "}
            </Message>
          ) : (
            <ListGroup variant="flush">
              {cartItems.map((item) => (
                <ListGroup.Item key={item.product}>
                  <Row className="align-items-center">
                    <Col md={2}>
                      <Image
                        src={item && item.images && item.images[0].name}
                        alt={item.name}
                        fluid="true"
                        rounded
                      ></Image>
                    </Col>
                    <Col md={2}>
                      <Link to={`/product/${item.product}`}>{item.name}</Link>
                    </Col>
                    <Col md={2}>
                      <p>R{item.price}.00</p>
                    </Col>
                    <Col md={2}>
                      <p>
                        Size:{" "}
                        {size === "small" ? "S" : size === "medium" ? "M" : "L"}
                      </p>
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        as="select"
                        value={Number(item.qty)}
                        onChange={(e) =>
                          dispatch(
                            addToCart({
                              id: item.product,
                              qty: Number(e.target.value),
                            })
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item.product)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>
                  Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                  ) items
                </h2>
                R
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  Procceed to checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CartScreen;
