import { createSlice } from "@reduxjs/toolkit";
import {
  createOrder,
  getOrderDetails,
  payOrder,
  listMyOrders,
  listOrders,
  deliverOrder,
} from "../actions/orderActions";

export const orderCreateSlice = createSlice({
  name: "order",
  initialState: {
    order: null,
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetOrder: (state) => {
      state.order = null;
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
        state.success = true;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const orderDetailsSlice = createSlice({
  name: "orderCreate",
  initialState: {
    order: null,
    itemsPrice: 0,
    loading: false,
    error: null,
  },
  reducers: {
    setItemsPrice: (state, action) => {
      state.itemsPrice = action.payload;
    },
    resetOrderDetails: (state) => {
      state.order = null;
      state.itemsPrice = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const orderPaySlice = createSlice({
  name: "order",
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetPayOrder: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(payOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(payOrder.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const orderListMySlice = createSlice({
  name: "order",
  initialState: {
    orders: null,
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetListMyOrders: (state) => {
      state.orders = null;
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listMyOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listMyOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
        state.success = true;
      })
      .addCase(listMyOrders.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const orderListSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetListOrders: (state) => {
      state.orders = [];
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
        state.success = true;
      })
      .addCase(listOrders.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const orderDeliverSlice = createSlice({
  name: "order",
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetDeliverOrder: (state) => {
      state = {
        success: false,
        loading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deliverOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deliverOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deliverOrder.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const { resetPayOrder } = orderPaySlice.actions;
export const { resetListMyOrders } = orderListMySlice.actions;
export const { resetDeliverOrder } = orderDeliverSlice.actions;
export const { setItemsPrice } = orderDetailsSlice.actions;
export const { resetOrder } = orderCreateSlice.actions;
export const { resetOrderDetails } = orderDetailsSlice.actions;
export const { resetListOrders } = orderListSlice.actions;
