import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Button, Container, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  listProductDetails,
  updateProduct,
  uploadImageOne,
  uploadImageTwo,
  uploadImageThree,
} from "../redux/actions/productActions";
import FormContainer from "../components/FormContainer";

const ProductEditScreen = () => {
  const { id: productId } = useParams();
  const history = useNavigate();

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [smallStock, setSmallStock] = useState(0);
  const [mediumStock, setMediumStock] = useState(0);
  const [largeStock, setLargeStock] = useState(0);
  const [description, setDescription] = useState("");

  const [imageOne, setImageOne] = useState("");
  const [imageTwo, setImageTwo] = useState("");
  const [imageThree, setImageThree] = useState("");

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const uploadImageOneState = useSelector((state) => state.imageOne);
  const { status: loadingOneStatus, image: imageOneUpload } =
    uploadImageOneState;

  const uploadImageTwoState = useSelector((state) => state.imageTwo);
  const { status: loadingTwoStatus, image: imageTwoUpload } =
    uploadImageTwoState;

  const uploadImageThreeState = useSelector((state) => state.imageThree);
  const { status: loadingThreeStatus, image: imageThreeUpload } =
    uploadImageThreeState;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (!product || !product.name || product._id !== productId) {
      dispatch(listProductDetails(productId));
    } else {
      setName(product.name);
      setPrice(product.price);
      setImageOne(product.images[0].name);
      setImageTwo(product.images[1].name);
      setImageThree(product.images[2].name);
      setSmallStock(product.sizes[0].countInStock);
      setMediumStock(product.sizes[1].countInStock);
      setLargeStock(product.sizes[2].countInStock);
      setDescription(product.description);
    }
  }, [dispatch, product, productId]);

  useEffect(() => {
    if (successUpdate) {
      history("/admin/products");
    }
  }, [successUpdate, dispatch, history]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        imageOne,
        imageTwo,
        imageThree,
        description,
        smallStock,
        mediumStock,
        largeStock,
      })
    );
  };

  useEffect(() => {
    if (loadingOneStatus === "success" && imageOneUpload) {
      setImageOne(`/api/upload/image/${imageOneUpload}`);
    }
  }, [loadingOneStatus, imageOneUpload]);

  useEffect(() => {
    if (loadingTwoStatus === "success" && imageTwoUpload) {
      setImageTwo(`/api/upload/image/${imageTwoUpload}`);
    }
  }, [loadingTwoStatus, imageTwoUpload]);

  useEffect(() => {
    if (loadingThreeStatus === "success" && imageThreeUpload) {
      setImageThree(`/api/upload/image/${imageThreeUpload}`);
    }
  }, [loadingThreeStatus, imageThreeUpload]);

  const firstUploadHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(
      uploadImageOne({
        image: formData,
      })
    );
  };

  const secondUploadHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(
      uploadImageTwo({
        image: formData,
      })
    );
  };

  const thirdUploadHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    dispatch(
      uploadImageThree({
        image: formData,
      })
    );
  };

  return (
    <Container style={{ paddingBottom: "3rem" }}>
      <Link to="/admin/products" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader></Loader>
        ) : error ? (
          <Message variant="danger">{error.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="price" className="mt-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="prodimage" className="mt-3">
                  <Image
                    className="w-100"
                    src={
                      imageOne === ""
                        ? product && product.images && product.images[0].name
                        : imageOne
                    }
                  ></Image>
                  {loadingOneStatus === "loading" && <Loader />}
                </Form.Group>
                <Form.Group controlId="image" className="mt-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    label="Choose File"
                    onChange={firstUploadHandler}
                  ></Form.Control>
                  {loadingOneStatus === "loading" && <Loader />}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="prodimage" className="mt-3">
                  <Image
                    className="w-100"
                    src={
                      imageTwo === ""
                        ? product && product.images && product.images[0].name
                        : imageTwo
                    }
                  ></Image>
                  {loadingTwoStatus === "loading" && <Loader />}
                </Form.Group>
                <Form.Group controlId="image" className="mt-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    label="Choose File"
                    onChange={secondUploadHandler}
                  ></Form.Control>
                  {loadingTwoStatus === "loading" && <Loader />}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="prodimage" className="mt-3">
                  <Image
                    className="w-100"
                    src={
                      imageThree === ""
                        ? product && product.images && product.images[0].name
                        : imageThree
                    }
                  ></Image>
                  {loadingThreeStatus === "loading" && <Loader />}
                </Form.Group>
                <Form.Group controlId="image" className="mt-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    label="Choose File"
                    onChange={thirdUploadHandler}
                  ></Form.Control>
                  {loadingThreeStatus === "loading" && <Loader />}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="smallstock" className="mt-3">
                  <Form.Label>Small Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter stock for small size"
                    value={smallStock}
                    onChange={(e) => setSmallStock(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="mediumstock" className="mt-3">
                  <Form.Label>Medium Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter stock for meidum size"
                    value={mediumStock}
                    onChange={(e) => setMediumStock(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="largestock" className="mt-3">
                  <Form.Label>Large Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter stock for large size"
                    value={largeStock}
                    onChange={(e) => setLargeStock(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter description"
                value={description}
                as="textarea"
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit" className="mt-3 w-100" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </Container>
  );
};

export default ProductEditScreen;
