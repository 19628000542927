import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios.instance";
import { logout } from "./userActions";

export const createOrder = createAsyncThunk(
  "orderCreate/createOrder",
  async (order, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.post("/api/orders", order, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "order/getOrderDetails",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.get(`/api/orders/${id}`, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);
      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const payOrder = createAsyncThunk(
  "order/payOrder",
  async ({ id, paymentResult }, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/api/orders/${id}/pay`,
        paymentResult,
        config
      );

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deliverOrder = createAsyncThunk(
  "order/deliverOrder",
  async (id, { rejectWithValue, getState, dispatch }) => {
    console.log("orderId", id);
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.put(`/api/orders/${id}/deliver`, {}, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listMyOrders = createAsyncThunk(
  "order/listMyOrders",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.get(`/api/orders/myorders/${id}`, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listOrders = createAsyncThunk(
  "order/listOrders",
  async (__, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.get("/api/orders", config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);
