import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers, deleteUser } from "../redux/actions/userActions";
import {
  Stack,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  TableHead,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

const UserListScreen = () => {
  const dispatch = useDispatch();

  const history = useNavigate();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      history("/login");
    }
  }, [dispatch, history, userInfo, successDelete]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteUser(id));
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Helmet>
        <title>Users | Emom</title>
      </Helmet>
      {loading ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "80vw",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        users && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100%",
              backgroundColor: "#f2f2f2",
              pb: 5,
              px: 5,
            }}
          >
            <Stack
              sx={{
                flex: 1,
                px: 4,
                py: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack sx={{ flex: 1 }}>
                <Typography variant="h4" sx={{ fontWeight: "600" }}>
                  Users
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ flex: 10 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="center">Admin</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{user._id}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell align="center">
                          {user.isAdmin ? <CheckIcon /> : <CloseIcon />}
                        </TableCell>
                        <TableCell align="center">
                          <Link to={`/admin/user/${user._id}/edit`}>
                            <IconButton aria-label="delete">
                              <EditIcon />
                            </IconButton>
                          </Link>
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteHandler(user._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        )
      )}
    </Stack>
    // <Container style={{ paddingTop: "3rem" }}>
    //   <h1>Users</h1>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <Message variant="danger">{error}</Message>
    //   ) : (
    //     <Table striped bordered hover responsive className="table-sm">
    //       <thead>
    //         <tr>
    //           <th>ID</th>
    //           <th>NAME</th>
    //           <th>EMAIL</th>
    //           <th>ADMIN</th>
    //           <th></th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {users.map((user) => (
    //           <tr key={user._id}>
    //             <td>{user._id}</td>
    //             <td>{user.name}</td>
    //             <td>
    //               <a href={`mailto:${user.email}`}>{user.email}</a>
    //             </td>
    //             <td>
    //               {user.isAdmin ? (
    //                 <i className="fas fa-check" style={{ color: "green" }}></i>
    //               ) : (
    //                 <i className="fas fa-times" style={{ color: "red" }}></i>
    //               )}
    //             </td>
    //             <td>
    //               <LinkContainer to={`/admin/user/${user._id}/edit`}>
    //                 <Button variant="light" className="btn-sm">
    //                   <i className="fas fa-edit"></i>
    //                 </Button>
    //               </LinkContainer>
    //               <Button
    //                 variant="danger"
    //                 className="btn-sm"
    //                 onClick={() => deleteHandler(user._id)}
    //               >
    //                 <i className="fas fa-trash"></i>
    //               </Button>
    //             </td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </Table>
    //   )}
    // </Container>
  );
};

export default UserListScreen;
