import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Image,
  Container,
  Toast,
} from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import { Helmet } from "react-helmet-async";

const ContactScreen = () => {
  const loading = false;
  const error = false;

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showToast, setShowToast] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);

  return (
    <React.Fragment>
      <Meta title={"Get in touch"} />
      <Container
        fluid="true"
        className="py-5 d-flex align-items-center justify-content-center"
      >
        <Helmet>
          <title>Contact Us | Emom</title>
        </Helmet>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error.message}</Message>
        ) : (
          <Container
            className="d-flex flex-column justify-content-center"
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
            }}
          >
            <Toast
              autohide
              delay={3000}
              show={showToast}
              onClose={toggleShowToast}
              className="p-3"
              style={{
                position: "fixed",
                bottom: "3%",
                right: "3%",
                backgroundColor: "green",
              }}
            >
              <Toast.Body className="mx-5 d-flex align-items-center">
                <h5 className="text-white p-0 m-0">Success!</h5>
              </Toast.Body>
            </Toast>
            <Row className="d-flex flex-row justify-content-between p-5">
              <Col md={6}>
                <Image className="w-100" src="/images/contact.jpg"></Image>
              </Col>
              <Col md={6} className="p-3">
                <h1
                  className="pb-4"
                  style={{ lineHeight: "0.5em", fontSize: "2.5em" }}
                >
                  Get in touch
                </h1>
                <h6 className="pb-1">(+27) 81 304 2095</h6>
                <h6 className="pb-1">(+27) 73 512 2047</h6>
                <h6 className="pb-1">info@emom.co.za</h6>
                <h6 className="pb-3">Johannesburg, South Africa</h6>
                <Form className="pr-5">
                  <Row>
                    <Col lg={6} sm={12}>
                      <Form.Group>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          placeholder="First name"
                          required
                          className="pl-0"
                          style={{
                            backgroundColor: "transparent",
                            resize: "none",
                            borderBottom: "1px solid #000",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          value={surname}
                          onChange={(e) => setSurname(e.target.value)}
                          type="text"
                          className="pl-0"
                          required
                          placeholder="Last name"
                          style={{
                            backgroundColor: "transparent",
                            resize: "none",
                            borderBottom: "1px solid #000",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mt-4">
                    <Form.Control
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      required
                      className="pl-0"
                      placeholder="Email"
                      style={{
                        backgroundColor: "transparent",
                        resize: "none",
                        borderBottom: "1px solid #000",
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="mt-5">
                    <Form.Control
                      as="textarea"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                      rows={2}
                      placeholder="Tell us all about it"
                      className="pl-0"
                      style={{
                        backgroundColor: "transparent",
                        resize: "none",
                        borderBottom: "1px solid #000",
                      }}
                    ></Form.Control>
                  </Form.Group>
                  <Button className="mt-3" type="submit">
                    send message
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        )}
      </Container>
    </React.Fragment>
  );
};

export default ContactScreen;
