import { configureStore } from "@reduxjs/toolkit";
import { cartSlice } from "./slices/cartSlices";
import { exampleSlice } from "./slices/exampleSlice";
import {
  orderCreateSlice,
  orderDeliverSlice,
  orderDetailsSlice,
  orderListMySlice,
  orderListSlice,
  orderPaySlice,
} from "./slices/orderSlices";
import {
  productCreateSlice,
  productDeleteSlice,
  productDetailsSlice,
  productListSlice,
  productReviewSlice,
  productNewSlice,
  productSaleSlice,
  productUpdateSlice,
  imageOneSlice,
  imageTwoSlice,
  imageThreeSlice,
} from "./slices/productSlices";
import {
  userDeleteSlice,
  userListSlice,
  userLoginSlice,
  userRegisterSlice,
  userUpdateProfileSlice,
  userUpdateSlice,
  userDetailsSlice,
} from "./slices/userSlices";
import { contactFormSlice } from "./slices/mailSlices";

export const store = configureStore({
  reducer: {
    exampleState: exampleSlice.reducer,
    productList: productListSlice.reducer,
    productDetails: productDetailsSlice.reducer,
    productDelete: productDeleteSlice.reducer,
    productCreate: productCreateSlice.reducer,
    productUpdate: productUpdateSlice.reducer,
    productReviewCreate: productReviewSlice.reducer,
    productNew: productNewSlice.reducer,
    productSale: productSaleSlice.reducer,
    cart: cartSlice.reducer,
    userLogin: userLoginSlice.reducer,
    userRegister: userRegisterSlice.reducer,
    userDetails: userDetailsSlice.reducer,
    userUpdateProfile: userUpdateProfileSlice.reducer,
    userUpdate: userUpdateSlice.reducer,
    userList: userListSlice.reducer,
    userDelete: userDeleteSlice.reducer,
    orderCreate: orderCreateSlice.reducer,
    orderDetails: orderDetailsSlice.reducer,
    orderPay: orderPaySlice.reducer,
    orderListMy: orderListMySlice.reducer,
    orderList: orderListSlice.reducer,
    orderDeliver: orderDeliverSlice.reducer,
    contactForm: contactFormSlice.reducer,
    imageOne: imageOneSlice.reducer,
    imageTwo: imageTwoSlice.reducer,
    imageThree: imageThreeSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
