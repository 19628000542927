import { createSlice } from "@reduxjs/toolkit";
import {
  listProducts,
  listProductDetails,
  deleteProduct,
  createProduct,
  updateProduct,
  createProductReview,
  listNewProducts,
  listSaleProducts,
  uploadImageOne,
  uploadImageTwo,
  uploadImageThree,
} from "../actions/productActions";

const initialState = {
  products: [],
  loading: false,
  error: null,
};

export const productListSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProductList: (state) => {
      state.products = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.pages = action.payload.pages;
        state.page = action.payload.page;
      })
      .addCase(listProducts.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState: {
    product: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductDetails: (state) => {
      state.product = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProductDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listProductDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
      })
      .addCase(listProductDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productDeleteSlice = createSlice({
  name: "product",
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductDelete: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productCreateSlice = createSlice({
  name: "product",
  initialState: {
    product: {},
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetCreateProduct: (state) => {
      state.product = {};
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.product = action.payload;
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const imageOneSlice = createSlice({
  name: "imageOne",
  initialState: {
    image: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetImageUpload: (state) => {
      state.image = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImageOne.pending, (state, action) => {
        console.log("IDLE");
        state.status = "loading";
        state.error = null;
      })
      .addCase(uploadImageOne.fulfilled, (state, action) => {
        console.log("SUCCESS");
        state.status = "success";
        state.image = action.payload;
        state.error = null;
      })
      .addCase(uploadImageOne.rejected, (state, action) => {
        console.log("FAIL");
        state.status = "idle";
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = { message: "Unknown error" };
        }
      });
  },
});

export const imageTwoSlice = createSlice({
  name: "imageTwo",
  initialState: {
    image: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetImageUpload: (state) => {
      state.image = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImageTwo.pending, (state, action) => {
        console.log("IDLE");
        state.status = "loading";
        state.error = null;
      })
      .addCase(uploadImageTwo.fulfilled, (state, action) => {
        console.log("SUCCESS");
        state.status = "success";
        state.image = action.payload;
        state.error = null;
      })
      .addCase(uploadImageTwo.rejected, (state, action) => {
        console.log("FAIL");
        state.status = "idle";
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = { message: "Unknown error" };
        }
      });
  },
});

export const imageThreeSlice = createSlice({
  name: "imageThree",
  initialState: {
    image: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetImageUpload: (state) => {
      state.image = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImageThree.pending, (state, action) => {
        console.log("IDLE");
        state.status = "loading";
        state.error = null;
      })
      .addCase(uploadImageThree.fulfilled, (state, action) => {
        console.log("SUCCESS");
        state.status = "success";
        state.image = action.payload;
        state.error = null;
      })
      .addCase(uploadImageThree.rejected, (state, action) => {
        console.log("FAIL");
        state.status = "idle";
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = { message: "Unknown error" };
        }
      });
  },
});

export const productUpdateSlice = createSlice({
  name: "product",
  initialState: {
    product: null,
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductUpdate: (state) => {
      state.product = null;
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProduct.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.product = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productReviewSlice = createSlice({
  name: "product",
  initialState: {
    success: false,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductReview: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProductReview.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProductReview.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createProductReview.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productNewSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetProductNew: (state) => {
      state.products = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listNewProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listNewProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(listNewProducts.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const productSaleSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetProductSale: (state) => {
      state.products = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listSaleProducts.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(listSaleProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(listSaleProducts.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const { resetCreateProduct } = productCreateSlice.actions;
export const { resetProductUpdate } = productUpdateSlice.actions;
export const { resetProductReview } = productReviewSlice.actions;
export const { resetProductNew } = productNewSlice.actions;
export const { resetProductSale } = productSaleSlice.actions;
export const { resetProductList } = productListSlice.actions;
export const { resetProductDetails } = productDetailsSlice.actions;
export const { resetProductDelete } = productDeleteSlice.actions;
