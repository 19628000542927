import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios.instance";
import { logout } from "./userActions";

export const listProducts = createAsyncThunk(
  "product/listProducts",
  async ({ keyword = "", pageNumber = "" }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
      );

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listProductDetails = createAsyncThunk(
  "productDetails/listProductDetails",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/products/${id}`);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axiosInstance.delete(`/api/products/${id}`, config);
      return;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (__, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      console.log("create userInfo", userInfo);

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.post(`/api/products`, {}, config);

      console.log("new product", data);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);
      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadImageOne = createAsyncThunk(
  "imageOne/image",
  async (imageUpload, thunkApi) => {
    const response = await axiosInstance.post(`/api/upload`, imageUpload.image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("FETCHING");

    if (response.data.errors) {
      const error = {
        message: response.data.errors[0].message,
      };

      return thunkApi.rejectWithValue(error);
    }

    console.log(response.data);

    let res = response.data.imagePath;

    return res;
  }
);

export const uploadImageTwo = createAsyncThunk(
  "imageTwo/image",
  async (imageUpload, thunkApi) => {
    const response = await axiosInstance.post(`/api/upload`, imageUpload.image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("FETCHING");

    if (response.data.errors) {
      const error = {
        message: response.data.errors[0].message,
      };

      return thunkApi.rejectWithValue(error);
    }

    console.log(response.data);

    let res = response.data.imagePath;

    return res;
  }
);

export const uploadImageThree = createAsyncThunk(
  "imageThree/image",
  async (imageUpload, thunkApi) => {
    const response = await axiosInstance.post(`/api/upload`, imageUpload.image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("FETCHING");

    if (response.data.errors) {
      const error = {
        message: response.data.errors[0].message,
      };

      return thunkApi.rejectWithValue(error);
    }

    console.log(response.data);

    let res = response.data.imagePath;

    return res;
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async (product, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/api/products/${product._id}`,
        product,
        config
      );

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createProductReview = createAsyncThunk(
  "product/createProductReview",
  async ({ productId, review }, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axiosInstance.post(`/api/products/${productId}/reviews`, review, config);

      return;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listNewProducts = createAsyncThunk(
  "product/listNewProducts",
  async (__, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/products/new`);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listSaleProducts = createAsyncThunk(
  "product/listSaleProducts",
  async (__, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/api/products/sale`);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);
