import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Product from "../components/Product";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../redux/actions/productActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import Paginate from "../components/Paginate";
import Meta from "../components/Meta";
import { Helmet } from "react-helmet-async";

const ShopScreen = () => {
  const dispatch = useDispatch();

  const { keyword } = useParams();
  const { pageNumber } = useParams() || 1;

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts({ keyword, pageNumber }));
  }, [dispatch, keyword, pageNumber]);

  return (
    <React.Fragment>
      <Meta />
      <Helmet>
        <title>Shop | Emom</title>
      </Helmet>
      <Container>
        <h1>Welcome to Our Shop</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error.message}</Message>
        ) : (
          <React.Fragment>
            <Row>
              <Col md={10}>
                <Row>
                  {products.map((product) => (
                    <Col
                      key={product._id}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={3}
                      className="d-flex align-items-stretch"
                    >
                      <Product product={product} variant="light"></Product>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Paginate
              pages={pages}
              page={page}
              keyword={keyword ? keyword : ""}
            />
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

export default ShopScreen;
