import { createSlice } from "@reduxjs/toolkit";
import {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
} from "../actions/cartActions";

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : null;

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : null;

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
    itemsPrice: 0,
    shippingPrice: 0,
    taxPrice: 0,
    totalPrice: 0,
    loading: false,
    error: null,
  },
  reducers: {
    addItemsprice: (state, action) => {
      state.itemsPrice = action.payload;
    },
    addShippingPrice: (state, action) => {
      state.shippingPrice = action.payload;
    },
    addTaxPrice: (state, action) => {
      state.taxPrice = action.payload;
    },
    addTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
    resetCart: (state) => {
      localStorage.removeItem("cartItems");

      state.cartItems = [];
      state.shippingAddress = null;
      state.paymentMethod = null;
      state.itemsPrice = 0;
      state.shippingPrice = 0;
      state.taxPrice = 0;
      state.totalPrice = 0;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.loading = false;
        const item = action.payload;

        const existItem = state.cartItems.find(
          (x) => x.product === item.product
        );

        if (existItem) {
          state.cartItems = state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          );
        } else {
          state.cartItems = [...state.cartItems, item];
        }
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      })
      .addCase(removeFromCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.loading = false;
        console.log("remove id", action.payload);
        state.cartItems = state.cartItems.filter(
          (x) => x.product !== action.payload
        );

        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      })
      .addCase(saveShippingAddress.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(saveShippingAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.shippingAddress = action.payload;
      })
      .addCase(saveShippingAddress.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      })
      .addCase(savePaymentMethod.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(savePaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentMethod = action.payload;
      })
      .addCase(savePaymentMethod.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = { message: action.payload };
        } else {
          state.error = { message: action.error };
        }
      });
  },
});

export const {
  addItemsprice,
  addShippingPrice,
  addTaxPrice,
  addTotalPrice,
  resetCart,
} = cartSlice.actions;
