import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios.instance";
import {
  resetUserDetails,
  resetUserList,
  resetUpdateProfile,
  resetUserUpdate,
  resetUserDelete,
  resetRegister,
} from "../slices/userSlices";
import {
  resetOrder,
  resetDeliverOrder,
  resetPayOrder,
  resetListMyOrders,
  resetOrderDetails,
  resetListOrders,
} from "../slices/orderSlices";
import {
  resetCreateProduct,
  resetProductUpdate,
  resetProductReview,
  resetProductList,
  resetProductDelete,
  resetProductDetails,
} from "../slices/productSlices";

export const login = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axiosInstance.post(
        "/api/users/login",
        { email, password },
        config
      );

      localStorage.setItem("userInfo", JSON.stringify(res.data));

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (__, { rejectWithValue, dispatch }) => {
    try {
      localStorage.removeItem("userInfo");

      dispatch(resetUserDetails());
      // dispatch(resetUserList());
      dispatch(resetUpdateProfile());
      dispatch(resetUserUpdate());
      dispatch(resetUserDelete());
      dispatch(resetRegister());

      dispatch(resetListMyOrders());
      dispatch(resetUserList());
      dispatch(resetOrder());
      dispatch(resetDeliverOrder());
      dispatch(resetPayOrder());
      dispatch(resetOrderDetails());
      dispatch(resetListOrders());

      dispatch(resetCreateProduct());
      dispatch(resetProductUpdate());
      dispatch(resetProductReview());
      // dispatch(resetProductNew());
      // dispatch(resetProductSale());
      dispatch(resetProductList());
      dispatch(resetProductDelete());
      dispatch(resetProductDetails());

      return null;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async ({ name, email, password }, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axiosInstance.post(
        "/api/users",
        { name, email, password },
        config
      );

      localStorage.setItem("userInfo", JSON.stringify(res.data));

      dispatch(login({ email, password }));

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "userDetails/getUserDetails",
  async (userId, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const res = await axiosInstance.get(`/api/users/${userId}`, config);

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.put(`/api/users/profile`, user, config);

      //   dispatch({
      //     type: USER_UPDATE_PROFILE_SUCCESS,
      //     payload: data,
      //   });

      //   dispatch({
      //     USER_LOGIN_SUCCESS,
      //     payload: data,
      //   });

      localStorage.setItem("userInfo", JSON.stringify(data));

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const listUsers = createAsyncThunk(
  "user/listUsers",
  async (__, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.get(`/api/users`, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axiosInstance.delete(`/api/users/${userId}`, config);

      return;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (user, { rejectWithValue, getState, dispatch }) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axiosInstance.put(`/api/users/${user._id}`, user, config);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      console.log(error.response.data);

      if (error.response.status === 401) {
        dispatch(logout());
      }

      return rejectWithValue(error.response.data.message);
    }
  }
);
