import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import { Fragment } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./pages/HomeScreen";
import CartScreen from "./pages/CartScreen";
import ProductScreen from "./pages/ProductScreen";
import LoginScreen from "./pages/LoginScreen";
import RegisterScreen from "./pages/RegisterScreeen";
import ProfileScreen from "./pages/ProfileScreen";
import ShippingScreen from "./pages/ShippingScreen";
import PaymentScreen from "./pages/PaymentScreen";
import PlaceOrderScreen from "./pages/PlaceOrderScreen";
import OrderScreen from "./pages/OrderScreen";
import UserListScreen from "./pages/UserListScreen";
import UserEditScreen from "./pages/UserEditScreen";
import ProductListScreen from "./pages/ProductListScreen";
import ProductEditScreen from "./pages/ProductEditScreen";
import OrderListScreen from "./pages/OrderListScreen";
// import AboutScreen from "./pages/AboutScreen";
import ContactScreen from "./pages/ContactScreen";
import ShopScreen from "./pages/ShopScreen";
import CheckoutScreen from "./pages/CheckoutScreen";
import AdminHeader from "./components/AdminHeader";
import AdminDashboard from "./pages/AdminDashboard";
import { Box } from "@mui/material";

function AppRoutes() {
  const location = useLocation();

  return (
    <Fragment>
      <CssBaseline />
      <main>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt:
              location.pathname !== "/" &&
              !location.pathname.includes("admin") &&
              13,
          }}
        >
          {location.pathname.includes("admin") ? <AdminHeader /> : <Header />}
          <Routes>
            <Route path="/admin/dashboard" element={<AdminDashboard />}></Route>
            <Route path="/admin/users" element={<UserListScreen />}></Route>
            <Route
              path="/admin/products"
              element={<ProductListScreen />}
            ></Route>
            <Route
              path="/admin/products/:pageNumber"
              element={<ProductListScreen />}
            ></Route>
            <Route path="/admin/orders" element={<OrderListScreen />}></Route>
            <Route
              path="/admin/product/:id/edit"
              element={<ProductEditScreen />}
            ></Route>
            <Route
              path="/admin/user/:id/edit"
              element={<UserEditScreen />}
            ></Route>
            <Route path="/checkout" element={<CheckoutScreen />}></Route>
            <Route path="/order/:id" element={<OrderScreen />}></Route>
            <Route path="/login/shipping" element={<ShippingScreen />}></Route>
            <Route path="/payment" element={<PaymentScreen />}></Route>
            <Route path="/placeorder" element={<PlaceOrderScreen />}></Route>
            <Route path="/login" element={<LoginScreen />}></Route>
            <Route path="/register" element={<RegisterScreen />}></Route>
            <Route path="/profile" element={<ProfileScreen />}></Route>
            <Route path="/product/:id" element={<ProductScreen />}></Route>
            <Route path="/cart/:id" element={<CartScreen />}></Route>
            <Route path="/cart" element={<CartScreen />}></Route>
            <Route path="/search/:keyword" element={<HomeScreen />}></Route>
            <Route path="/page/:pageNumber" element={<HomeScreen />}></Route>
            <Route
              path="/search/:keyword/page/:pageNumber"
              element={<HomeScreen />}
            ></Route>
            {/* <Route path="/about" element={<AboutScreen />}></Route> */}
            <Route path="/contact" element={<ContactScreen />}></Route>
            <Route path="/shop" element={<ShopScreen />}></Route>
            <Route path="/" element={<HomeScreen />}></Route>
          </Routes>
        </Box>
      </main>
      {location.pathname.includes("admin") ? null : <Footer />}
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <AppRoutes />
    </Router>
  );
}

export default App;
