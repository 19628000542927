import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Container>
      <footer className="border-top mt-4">
        <Container>
          <Row className="p-2 pt-3">
            <Col className="d-flex align-items-center">
              <Image
                src="/images/emom_logo.png"
                fluid="true"
                className="w-50"
              ></Image>
            </Col>
            <Col className="d-flex align-items-center justify-content-around">
              <Link to="/" style={{ fontSize: "1.1rem" }}>
                Home
              </Link>
              <Link to="/shop" style={{ fontSize: "1.1rem" }}>
                Shop
              </Link>
              <Link to="/contact" style={{ fontSize: "1.1rem" }}>
                Contact Us
              </Link>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <a href="facebook.com" target="_blank" rel="noopener noreferrer">
                <i
                  className="fa-brands fa-facebook"
                  style={{ fontSize: "1.5rem", marginRight: "2rem" }}
                ></i>
              </a>
              <a href="instagram.com" target="_blank" rel="noopener noreferrer">
                <i
                  className="fa-brands fa-instagram"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="text-center py-3">
              Copyright {new Date().getFullYear()} &copy; Emom
            </Col>
          </Row>
        </Container>
      </footer>
    </Container>
  );
};

export default Footer;
