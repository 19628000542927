import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  listNewProducts,
  listSaleProducts,
} from "../redux/actions/productActions";
import { contactForm } from "../redux/actions/mailActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import Meta from "../components/Meta";
import styled from "styled-components";
import Product from "../components/Product";
import { Link } from "react-router-dom";
import { resetContactForm } from "../redux/slices/mailSlices";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const StyledHero = styled(Container)`
  background-image: url(/images/home_hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
`;

const StyledButton = styled(Button)`
  color: #000;
  background: #fff;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  font-size: 1.1rem;
  padding: 12px 35px;
  display: inline-block;
  transform: skew(-21deg);
`;

const HomeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { keyword } = useParams();
  const { pageNumber } = useParams() || 1;

  const [showToast, setShowToast] = useState(false);

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const toggleShowToast = () => setShowToast(!showToast);

  const userState = useSelector((state) => state.userLogin);
  const { userInfo } = userState;

  const newProductList = useSelector((state) => state.productNew);
  const {
    loading: newLoading,
    error: newError,
    products: newProducts,
  } = newProductList;

  const saleProductList = useSelector((state) => state.productSale);
  const {
    loading: saleLoading,
    error: saleError,
    products: saleProducts,
  } = saleProductList;

  const contactFormState = useSelector((state) => state.contactForm);
  const { loading: mailLoading, error: mailError, success } = contactFormState;

  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate(0);
  //   }
  // }, [userInfo, navigate]);

  useEffect(() => {
    // if (success) {
    //   navigate(`/`);
    //   setShowToast(true);
    // }

    dispatch(listNewProducts());
    dispatch(listSaleProducts());
  }, [dispatch, keyword, pageNumber, navigate]);

  useEffect(() => {
    if (success) {
      setShowToast(true);
      setName("");
      setSurname("");
      setEmail("");
      setMessage("");
      dispatch(resetContactForm());
    }
  }, [success, dispatch]);

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(
      contactForm({ firstname: name, lastname: surname, email, message })
    );
  };

  return (
    <React.Fragment>
      <Meta />
      <Helmet>
        <title>Welcome | Emom</title>
      </Helmet>
      <Container fluid="true" className="p-0">
        <StyledHero
          className="vh-100 p-0 d-flex align-items-center justify-content-center"
          fluid="true"
        >
          <Row className="align-items-end h-50" style={{ maxWidth: "80%" }}>
            <Col className="align-items-center justify-content-center d-flex flex-column">
              <h1
                className="p-0 pb-3"
                style={{ fontSize: "4em", color: "#fff", fontWeight: "900" }}
              >
                EVERY MINUTE ON THE MINUTE
              </h1>
              <Link to="/shop">
                <StyledButton>Shop Now</StyledButton>
              </Link>
            </Col>
          </Row>
        </StyledHero>
        <Container fluid="true">
          <Row className="d-flex flex-column text-center h-100 py-5">
            <Col
              className="d-flex mt-3 w-100 align-self-center"
              style={{ padding: "0px 0 100px 0" }}
            >
              <Col className="d-flex flex-column justify-content-center align-items-center">
                <h1>About Us</h1>
                <p
                  className="pt-3 text-center w-50"
                  style={{ fontSize: "1.15em" }}
                >
                  Emom was designed to motivate women all around the world to
                  live a more active life. We believe that if women equip
                  themselves with stunning exercise clothes that are comfortable
                  and slimming, women will live with more confidence and an
                  active lifestyle will follow. We aspire to make women feel
                  their best while wearing the emom brand. Nicole and Carissa,
                  founders of emom, are very passionate about all types of
                  exercises, from running to swimming and tennis and want to
                  share this passion with all the ladies out there through the
                  emom exercise brand.
                </p>
              </Col>
            </Col>
            <Col className="d-flex flex-column justify-content-center align-items-center mb-5">
              <h1>Just Launched</h1>
              <Row
                className="mt-5 d-flex align-items-center"
                style={{ padding: "0 150px" }}
              >
                {newLoading ? (
                  <Loader />
                ) : newError ? (
                  <Message variant="danger">{newError}</Message>
                ) : (
                  <Row>
                    {newProducts.map((product, index) => (
                      <Col className="flex-column align-self-start" key={index}>
                        <Product product={product} variant="light" />
                      </Col>
                    ))}
                  </Row>
                )}
              </Row>
            </Col>
            <Col
              className="d-flex flex-column justify-content-center align-items-center mb-5 pb-3"
              style={{ backgroundColor: "#333" }}
            >
              <h1 className="pt-5 text-white">Sample Sale</h1>
              <Row
                className="mt-3 d-flex align-items-center"
                style={{ padding: "0 150px" }}
              >
                {saleLoading ? (
                  <Loader />
                ) : saleError ? (
                  <Message variant="danger">{saleError}</Message>
                ) : (
                  <Row>
                    {saleProducts.map((product, index) => (
                      <Col className="flex-column align-self-start" key={index}>
                        <Product product={product} />
                      </Col>
                    ))}
                  </Row>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container
          fluid="true"
          className="vh-100 py-5 d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: 'url("./images/home_hero.jpg")',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {newLoading ? (
            <Loader />
          ) : newError ? (
            <Message variant="danger">{newError}</Message>
          ) : (
            <Container
              className="shadow rounded d-flex flex-column justify-content-center"
              style={{
                backgroundColor: "rgba(255,255,255,0.9)",
              }}
            >
              <Toast
                autohide
                delay={3000}
                show={showToast}
                onClose={toggleShowToast}
                className="p-3"
                style={{
                  position: "fixed",
                  bottom: "3%",
                  right: "3%",
                  backgroundColor: "green",
                }}
              >
                <Toast.Body className="mx-5 d-flex align-items-center">
                  <h5 className="text-white p-0 m-0">Success!</h5>
                </Toast.Body>
              </Toast>
              <Row className="d-flex flex-row justify-content-between p-5">
                <Col className="p-3">
                  <h1
                    className="pb-4"
                    style={{ lineHeight: "1.5em", fontSize: "3em" }}
                  >
                    Get in touch with us.
                  </h1>
                  <Form className="pr-5" onSubmit={submitForm}>
                    <Row>
                      <Col lg={6} sm={12}>
                        <Form.Group>
                          <Form.Control
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="First name"
                            required
                            className="pl-0"
                            style={{
                              backgroundColor: "transparent",
                              resize: "none",
                              borderBottom: "1px solid #000",
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            type="text"
                            className="pl-0"
                            required
                            placeholder="Last name"
                            style={{
                              backgroundColor: "transparent",
                              resize: "none",
                              borderBottom: "1px solid #000",
                            }}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mt-4">
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        className="pl-0"
                        placeholder="Email"
                        style={{
                          backgroundColor: "transparent",
                          resize: "none",
                          borderBottom: "1px solid #000",
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="mt-5">
                      <Form.Control
                        as="textarea"
                        value={message}
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        rows={2}
                        placeholder="Tell us all about it"
                        className="pl-0"
                        style={{
                          backgroundColor: "transparent",
                          resize: "none",
                          borderBottom: "1px solid #000",
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Button className="mt-3" type="submit">
                      send message
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          )}
        </Container>
      </Container>
    </React.Fragment>
  );
};

export default HomeScreen;
